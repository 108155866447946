const navbarData = [
  {
    text: 'Home',
    elementName: 'hero',
  },
  {
    text: 'About',
    elementName: 'about',
  },
  {
    text: 'Projects',
    elementName: 'projects',
  },
  {
    text: 'Contact',
    elementName: 'contact',
  },
]

export {navbarData}
